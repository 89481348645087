import Toolbar from "src/components/Toolbar";
import { useTranslation } from "react-i18next";
import styles from "./AddLinkChooseBank.module.scss";
import { useHistory, useLocation } from "react-router-dom";
import SearchInput from "src/components/SearchInput";
import { useCallback, useEffect, useState } from "react";
import chevronRightImg from "src/assets/images/chevron-right.svg";
import { getBanks } from "src/api";
import Bank from "src/models/Bank";
import throttle from "lodash/throttle";
import Utils from "src/utils/Utils";
import noResultImg from "src/assets/images/no-result.svg";
import AccountService from "src/services/AccountService";
import bankImg from "src/assets/images/bank.png";
import Image from "src/components/Image";
import qs from "qs";

const AddLinkChooseBank = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
  const [keyword, setKeyword] = useState("");
  const [bankList, setBankList] = useState<Bank[]>();
  const [bankListDisplay, setBankListDisplay] = useState<Bank[]>();
  const [loading, setLoading] = useState<boolean>(true);

  const _loadBanks = useCallback(async () => {
    const savedBanks = AccountService.getBanks();
    if (savedBanks) {
      setBankList(savedBanks);
      setBankListDisplay(savedBanks);
      setLoading(false);
    }
    try {
      const bankListRes = await getBanks();
      console.log("bankListRes", bankListRes);
      setBankList(bankListRes?.data);
      setBankListDisplay(bankListRes?.data);
    } catch (err) {
      setLoading(false);
      console.log("Load bank err", err);
    }
  }, []);

  useEffect(() => {
    _loadBanks();
  }, [_loadBanks]);

  const _searchBank = throttle((keyword: string) => {
    if (!keyword) {
      setBankListDisplay(bankList);
    } else {
      const normalizedKeyword = Utils.removeAccent(keyword).toLowerCase();
      const matchedBank = bankList?.filter((item) => {
        const normalizedBankName = Utils.removeAccent(item.name).toLowerCase();
        const normalizedBankCode = Utils.removeAccent(item.code).toLowerCase();
        return (
          normalizedBankCode.indexOf(normalizedKeyword) > -1 ||
          normalizedBankName.indexOf(normalizedKeyword) > -1
        );
      });
      setBankListDisplay(matchedBank);
    }
  }, 500);

  const _handleChangeKeyword = (e) => {
    const newValue =
      e.target.value.length > 100
        ? e.target.value.substr(0, 100)
        : e.target.value;
    console.log("_handleChangeKeyword", newValue);
    setKeyword(newValue);
    _searchBank(newValue);
  };

  const _handleClearKeyword = () => {
    setKeyword("");
    _searchBank();
  };
  const _handleChooseBank = (item: Bank) => {
    console.log("Choosed bank", item);
    AccountService.saveSelectedBank(item);
    if (queryParams?.accountNo) {
      history.push(`/add-link-card-form?accountNo=${queryParams?.accountNo}`);
    } else {
      history.push("/add-link-card-form");
    }
  };

  const _renderBankItem = (item: Bank, index: number) => {
    return (
      <div
        className={
          index === bankListDisplay!.length - 1
            ? styles.bankItemLast
            : styles.bankItem
        }
        key={item.id}
        onClick={() => _handleChooseBank(item)}
      >
        <Image
          className={styles.bankItemImg}
          alt={item.name}
          src={item.photo}
        />
        <div className="flex1">
          <div className="title16 mb-1">{item.code}</div>
          <div className="body14 textGray">{item.name}</div>
        </div>
        <Image
          src={chevronRightImg}
          className={styles.chevronRight}
          alt="Chevron right"
        />
      </div>
    );
  };

  const _renderNoSearchResult = () => {
    return (
      <div className="fullWidth columnAlignCenter">
        <Image
          src={noResultImg}
          className={styles.noResultImg}
          alt="No result"
        />
        <div className="body14 textGray">{t("no-search-result")}</div>
      </div>
    );
  };

  const _renderEmptyState = () => {
    return (
      <div className={styles.emptyStateContainer}>
        <Image src={bankImg} className={styles.bankEmptyImg} alt={"No Bank"} />
      </div>
    );
  };

  return (
    <div className="screenContainer">
      <Toolbar title={t("add-new-link-card")} />
      <div className={"screenScrollContainer"}>
        <div className={styles.container}>
          <div className="title20 mb18">{t("choose-link-bank")}</div>
          <SearchInput
            value={keyword}
            onChange={_handleChangeKeyword}
            placeholder={t("search")}
            maxLength={100}
            onClear={_handleClearKeyword}
          />
        </div>
        {!loading && (!bankList || bankList.length === 0)
          ? _renderEmptyState()
          : !!keyword && bankListDisplay?.length === 0
          ? _renderNoSearchResult()
          : bankListDisplay?.map(_renderBankItem)}
        <div className="bottomBarSpacer" />
      </div>
    </div>
  );
};

export default AddLinkChooseBank;
