import { useMemo, useState } from "react";
import Account from "src/models/Account";
import styles from "./BankAccountDropdown.module.scss";
import carretDownImg from "src/assets/images/caret-down-fill.svg";
import ModalBottomSheet from "src/components/ModalBottomSheet";
import { useTranslation } from "react-i18next";
import radioCheckedImg from "src/assets/images/radio-checked.svg";
import radioDefaultImg from "src/assets/images/radio-default.svg";
import Button from "react-bootstrap/Button";
import UserInfoService from "src/services/UserInfoService";
import Utils from "src/utils/Utils";
import Image from "src/components/Image";

interface BankAccountDropdownProps {
  label: string;
  data?: Account[];
  value?: string;
  labelHelperIcon?: React.ReactNode;
  onChange?: (value: string) => void;
}
const BankAccountDropdown = ({
  data,
  value,
  label,
  labelHelperIcon,
  onChange,
}: BankAccountDropdownProps) => {
  const { t } = useTranslation();
  const [showBottomSheet, setShowBottomSheet] = useState(false);
  const [bottomSheetSelectedAccount, setBottomSheetSelectedAccount] =
    useState<string>();
  const _selectedAccount: Account | null | undefined = useMemo(() => {
    if (!value || !data || data.length === 0) return null;
    return data.find((item) => item.accountNo === value);
  }, [data, value]);

  const _handleClickInput = () => {
    setBottomSheetSelectedAccount(value);
    setShowBottomSheet(true);
  };

  const _handleCloseBottomSheet = () => {
    setShowBottomSheet(false);
  };

  const _handeClickAccountItem = (item: Account) => {
    setBottomSheetSelectedAccount(item.accountNo);
  };

  const _renderAccountItem = (item: Account, index: number) => {
    return (
      <div
        key={item.accountNo}
        className={styles.accountItem}
        onClick={() => _handeClickAccountItem(item)}
      >
        <div className="flex1">
          <div className="body16 w600 textBlack mb-1">
            {Utils.getAccountNoDisplay(item.accountNo)}
          </div>
          <div className="body14 textGray">
            {item.accountName || UserInfoService.getUserInfo()?.fullname}
          </div>
        </div>
        <Image
          src={
            bottomSheetSelectedAccount === item.accountNo
              ? radioCheckedImg
              : radioDefaultImg
          }
          alt="Radio"
          className={styles.radioImg}
        />
      </div>
    );
  };

  const _handleAgreeAccount = () => {
    setShowBottomSheet(false);
    if (
      bottomSheetSelectedAccount &&
      bottomSheetSelectedAccount !== value &&
      onChange
    ) {
      onChange(bottomSheetSelectedAccount!);
    }
  };

  return (
    <>
      <ModalBottomSheet
        visible={showBottomSheet}
        showHeader={true}
        title={t("choose-account")}
        onClose={_handleCloseBottomSheet}
      >
        <div className={styles.bottomSheetContent}>
          <div className={styles.accountContainer}>
            {data?.map(_renderAccountItem)}
          </div>
          <div className="mb-3">
            <Button
              variant="primary"
              onClick={_handleAgreeAccount}
              className="fullWidth"
            >
              {t("agree")}
            </Button>
          </div>
          <div className="mb-3">
            <Button
              variant="outline-primary"
              onClick={_handleCloseBottomSheet}
              className="fullWidth"
            >
              {t("ignore")}
            </Button>
          </div>
        </div>
      </ModalBottomSheet>
      <div>
        <div className="rowStart flex1 body14 w600 textGray form-label">
          {label}
          {labelHelperIcon && labelHelperIcon}
        </div>
        <div className={styles.input} onClick={_handleClickInput}>
          <div className={`${styles.valueContainer} flex1`}>
            {_selectedAccount
              ? `${Utils.getAccountNoDisplay(_selectedAccount.accountNo)} - ${
                  _selectedAccount.accountName ||
                  UserInfoService.getUserInfo()?.fullname
                }`
              : ""}
          </div>
          <Image
            src={carretDownImg}
            className={styles.caretDown}
            alt={"Caret down"}
          />
        </div>
      </div>
    </>
  );
};

export default BankAccountDropdown;
