import Toolbar from "src/components/Toolbar";
import { useTranslation } from "react-i18next";
import styles from "./LinkAndUnlink.module.scss";
import plusCircleImg from "src/assets/images/plus-circle-primary.svg";
import chevronRightImg from "src/assets/images/chevron-right.svg";
import mbImg from "src/assets/images/mb.svg";
import { useHistory } from "react-router-dom";
import Account from "src/models/Account";
import { useCallback, useEffect, useState } from "react";
import AccountService from "src/services/AccountService";
import { getAccounts } from "src/api";
import Utils from "src/utils/Utils";
import Image from "src/components/Image";

const LinkAndUnlink = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [accountList, setAccountList] = useState<Account[]>();

  const _handleAddLink = () => {
    history.push("/add-link-choose-bank");
  };

  const _clearFormInfo = () => {
    AccountService.clearChargeInfo();
    AccountService.clearSelectedBank();
    AccountService.clearLinkInfo();
  };

  const _loadLinkAccount = useCallback(async () => {
    const savedLinkedAccount = AccountService.getLinkedAccount();
    if (savedLinkedAccount) {
      setAccountList(savedLinkedAccount);
    }
    try {
      const accountListRes = await getAccounts(true);
      console.log("accountListRes", accountListRes);
      setAccountList(accountListRes?.data);
      AccountService.saveLinkedAccount(accountListRes?.data);
    } catch (err) {
      console.log("Load link account err", err);
    }
  }, []);

  useEffect(() => {
    _loadLinkAccount();
    _clearFormInfo();
  }, [_loadLinkAccount]);

  const _handleViewLink = (item: Account) => {
    history.push(`/link-card-detail?accountNo=${item.accountNo}`);
  };

  const _renderLinkedBankItem = (item: Account, index: number) => {
    return (
      <div
        className={"rowMenuItem2 mb-3"}
        key={item.accountNo}
        onClick={() => _handleViewLink(item)}
      >
        <Image src={mbImg} className={styles.bankImg} alt="Plus" />
        <div className="flex1">
          <div className="title16 mb-1">
            {Utils.getAccountNoDisplay(item.accountNo)}
          </div>
          <div className="body14 textGray">
            {item.nbLinks} {t("card-account")}
          </div>
        </div>
        <Image
          src={chevronRightImg}
          className={styles.chevronRight}
          alt="Chevron right"
        />
      </div>
    );
  };

  return (
    <div className="screenContainer">
      <Toolbar title={t("link-and-unlink-card")} />
      <div className={`${styles.container} screenScrollContainer`}>
        <div className={"rowMenuItem mb-4"} onClick={_handleAddLink}>
          <Image src={plusCircleImg} className={styles.plusImg} alt="Plus" />
          <div className={styles.menuName}>{t("add-new-link-card")}</div>
          <Image
            src={chevronRightImg}
            className={styles.chevronRight}
            alt="Chevron right"
          />
        </div>
        <div className="mb-4 body16">
          {t("list-linked-account")}{" "}
          {accountList && accountList?.length > 0
            ? `(${accountList?.length})`
            : ""}
        </div>
        {accountList?.map(_renderLinkedBankItem)}
      </div>
    </div>
  );
};

export default LinkAndUnlink;
