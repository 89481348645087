import Account from "src/models/Account";
import Bank from "src/models/Bank";
import ChargeInfo from "src/models/ChargeInfo";
import Config from "src/models/Config";
import LinkInfo from "src/models/LinkInfo";
class AccountService {
  static saveLinkedAccount(accountList: Account[]) {
    sessionStorage.setItem("linked_account", JSON.stringify(accountList));
  }

  static getLinkedAccount(): Account[] | null {
    const linkedAccountStr = sessionStorage.getItem("linked_account");
    if (!linkedAccountStr) return null;
    try {
      const linkedAccountList: Account[] = JSON.parse(linkedAccountStr);
      return linkedAccountList;
    } catch (err) {
      console.log("Parse linkedAccount err", err);
      return null;
    }
  }

  static saveListAccount(accountList: Account[]) {
    sessionStorage.setItem("list_account", JSON.stringify(accountList));
  }

  static getListAccount(): Account[] | null {
    const listAccountStr = sessionStorage.getItem("list_account");
    if (!listAccountStr) return null;
    try {
      const accountList: Account[] = JSON.parse(listAccountStr);
      return accountList;
    } catch (err) {
      console.log("Parse accountList err", err);
      return null;
    }
  }

  static saveBanks(banks: Bank[]) {
    sessionStorage.setItem("banks", JSON.stringify(banks));
  }

  static getBanks(): Bank[] | null {
    const banksStr = sessionStorage.getItem("banks");
    if (!banksStr) return null;
    try {
      const banks: Bank[] = JSON.parse(banksStr);
      return banks;
    } catch (err) {
      console.log("Parse banks err", err);
      return null;
    }
  }

  static saveSelectedBank(bank: Bank) {
    sessionStorage.setItem("selected_bank", JSON.stringify(bank));
  }

  static getSelectedBank(): Bank | null {
    const selectedBankStr = sessionStorage.getItem("selected_bank");
    if (!selectedBankStr) return null;
    try {
      const selectedBank: Bank = JSON.parse(selectedBankStr);
      return selectedBank;
    } catch (err) {
      console.log("Parse selected bank err", err);
      return null;
    }
  }

  static saveChargeInfo(info: ChargeInfo) {
    sessionStorage.setItem("charge_info", JSON.stringify(info));
  }

  static getChargeInfo(): ChargeInfo | null {
    const chargeInfoStr = sessionStorage.getItem("charge_info");
    if (!chargeInfoStr) return null;
    try {
      const chargeInfo: ChargeInfo = JSON.parse(chargeInfoStr);
      return chargeInfo;
    } catch (err) {
      console.log("Parse chargeInfo err", err);
      return null;
    }
  }

  static clearChargeInfo() {
    sessionStorage.removeItem("charge_info");
  }

  static saveLinkInfo(info: LinkInfo) {
    sessionStorage.setItem("link_info", JSON.stringify(info));
  }

  static getLinkInfo(): LinkInfo | null {
    const linkInfoStr = sessionStorage.getItem("link_info");
    if (!linkInfoStr) return null;
    try {
      const linkInfo: LinkInfo = JSON.parse(linkInfoStr);
      return linkInfo;
    } catch (err) {
      console.log("Parse linkInfo err", err);
      return null;
    }
  }

  static clearLinkInfo() {
    sessionStorage.removeItem("link_info");
  }

  static clearSelectedBank() {
    sessionStorage.removeItem("selected_bank");
  }

  static saveConfig(config: Config) {
    sessionStorage.setItem("config", JSON.stringify(config));
  }

  static getConfig(): Config | null {
    const configStr = sessionStorage.getItem("config");
    if (!configStr) return null;
    try {
      const config: Config = JSON.parse(configStr);
      return config;
    } catch (err) {
      console.log("Parse config err", err);
      return null;
    }
  }
}

export default AccountService;
