import { useCallback, useEffect, useState } from "react";
import Toolbar from "src/components/Toolbar";
import { useTranslation } from "react-i18next";
import styles from "./LinkCardDetail.module.scss";
import { useHistory, useLocation } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Carousel from "react-bootstrap/Carousel";
import { getLinks } from "src/api";
import qs from "qs";
import LinkCard from "src/models/LinkCard";
import arrowLeft from "src/assets/images/round-left-arrow.svg";
import arrowRight from "src/assets/images/round-right-arrow.svg";
import plusGrayImg from "src/assets/images/plus-gray.svg";
import linkGrayImg from "src/assets/images/link-gray.svg";
import napasImg from "src/assets/images/napas.png";
import UiService from "src/services/UiService";
import { requestUnlinkCard } from "src/api";
import Utils from "src/utils/Utils";
import Image from "src/components/Image";

const LinkCardDetail = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
  const accountNo = queryParams?.accountNo;
  const [listLinkCard, setListLinkCard] = useState<LinkCard[]>();
  const [showingCardIndex, setShowingCardIndex] = useState(0);

  const _loadLinkCard = useCallback(async () => {
    try {
      const linksRes = await getLinks(accountNo);
      console.log("linksRes", linksRes);
      setListLinkCard(linksRes?.data);
    } catch (err) {
      console.log("Load link err", err);
    }
  }, [accountNo]);

  useEffect(() => {
    _loadLinkCard();
  }, [_loadLinkCard]);

  const _handleAddNewCard = () => {
    const selectCard = listLinkCard?.[showingCardIndex];
    if (!selectCard) {
      history.push(`/add-link-choose-bank`);
    } else {
      history.push(`/add-link-choose-bank?accountNo=${selectCard.accountNo}`);
    }
  };

  const _getOpenDateDisplay = (openDate: string) => {
    if (!openDate) return "";
    const openDateSplit = openDate.split("-");
    if (openDateSplit.length === 1) return openDate;
    return openDateSplit.reverse().join("/");
  };

  const _renderCardItem = (item: LinkCard) => {
    return (
      <div className={styles.cardContainer}>
        <Image src={napasImg} className={styles.napasImg} alt={"Napas"} />
        <div className={styles.cardContent}>
          <div className={`${styles.cardTextBold} mb44`}>
            {item?.sourceBank?.code}
          </div>
          <div className={`${styles.cardNumber} mb20`}>
            **** **** **** {item.sourceCardEnding}
          </div>
          <div className="rowStart mb10">
            <span className={styles.memberSinceText}>Member since</span>
            <div className={`${styles.cardTextBold}`}>
              {_getOpenDateDisplay(item.sourceCardOpenDate)}
            </div>
          </div>
          <div className={`${styles.cardTextBold}`}>
            {item.sourceHolderName}
          </div>
        </div>
      </div>
    );
  };

  const _handlePrevious = () => {
    if (!listLinkCard) return;
    const numOfCard = listLinkCard.length;
    if (showingCardIndex > 0) {
      setShowingCardIndex(showingCardIndex - 1);
    } else {
      setShowingCardIndex(numOfCard - 1);
    }
  };
  const _handleNext = () => {
    if (!listLinkCard) return;
    const numOfCard = listLinkCard.length;
    if (showingCardIndex < numOfCard - 1) {
      setShowingCardIndex(showingCardIndex + 1);
    } else {
      setShowingCardIndex(0);
    }
  };

  const _renderControl = () => {
    if (
      !listLinkCard ||
      listLinkCard.length === 0 ||
      listLinkCard.length === 1
    ) {
      return <div />;
    }
    return (
      <div className="rowCenter mb44">
        <Image
          src={arrowLeft}
          className={styles.arrowLeft}
          alt="Arrow left"
          onClick={_handlePrevious}
        />
        <Image
          src={arrowRight}
          className={styles.arrowRight}
          alt="Arrow Right"
          onClick={_handleNext}
        />
      </div>
    );
  };

  const _handleCharge = () => {
    const selectCard = listLinkCard?.[showingCardIndex];
    if (!selectCard) {
      history.push("/charge");
    } else {
      history.push(
        `/charge?accountNo=${selectCard.accountNo}&selectCard=${selectCard.id}`
      );
    }
  };

  const _handleRemoveLink = () => {
    UiService.openPopup({
      title: t("delete-link-card"),
      content: t("confirm-delete-link-card"),
      okTitle: t("confirm"),
      cancelTitle: t("cancel"),
      onOk: () => {
        UiService.closePopup();
        _unlink();
      },
      onCancel: () => {
        UiService.closePopup();
      },
    });
  };

  const _unlink = async () => {
    const selectCard = listLinkCard?.[showingCardIndex];
    if (!selectCard) return;
    const displayCard = selectCard?.sourceBank?.code
      ? `${selectCard?.sourceBank?.code} **${selectCard.sourceCardEnding}`
      : `**${selectCard.sourceCardEnding}`;
    try {
      UiService.showLoading();
      const unlinkResult = await requestUnlinkCard(selectCard.id);
      console.log("unlinkResult", unlinkResult);
      UiService.hideLoading();
      history.push(
        `/remove-link-result?accountNo=${accountNo}&success=true&displayCard=${encodeURIComponent(
          displayCard
        )}`
      );
    } catch (err: any) {
      UiService.hideLoading();
      console.log("Unlink err", err?.response);
      if (err?.response?.status === 400) {
        history.push(
          `/remove-link-result?accountNo=${accountNo}&success=false&displayCard=${encodeURIComponent(
            displayCard
          )}`
        );
      }
    }
  };

  const _renderAction = () => {
    if (!listLinkCard || listLinkCard.length === 0) return <div />;
    return (
      <div className={`rowSpaceBetween fullWidth ${styles.actionContainer}`}>
        <div className="rowStart" onClick={_handleCharge}>
          <Image src={plusGrayImg} className={styles.actionIcon} alt="plus" />
          <div className="textGray w600">{t("charge").toUpperCase()}</div>
        </div>
        <div className="rowStart" onClick={_handleRemoveLink}>
          <Image src={linkGrayImg} className={styles.actionIcon} alt="link" />
          <div className="textGray w600">{t("remove-link").toUpperCase()}</div>
        </div>
      </div>
    );
  };

  const _renderDots = () => {
    const numOfCard = listLinkCard?.length || 0;
    if (numOfCard <= 1) {
      return <div className="mb36" />;
    }
    const dots: React.ReactNode[] = [];
    for (let i = 0; i < numOfCard; i++) {
      const isActive = i === showingCardIndex;
      const isLast = i === numOfCard - 1;
      dots.push(
        <div
          key={i}
          className={`${styles.dot} ${isActive ? "bgPrimary" : "bgGray4"} ${
            !isLast ? "me6" : ""
          }`}
        />
      );
    }

    return <div className="mt-3 rowCenter mb36">{dots}</div>;
  };

  const _handleSelectShowingCard = (selectedIndex, e) => {
    setShowingCardIndex(selectedIndex);
  };

  return (
    <div className="screenContainer">
      <Toolbar title={Utils.getAccountNoDisplay(accountNo)} />
      <div className={"screenScrollContainer background"}>
        <div className={styles.container}>
          <div className="title20 mb44">
            {t("list-linked-card")}{" "}
            {listLinkCard && listLinkCard?.length > 0
              ? `(${listLinkCard?.length})`
              : ""}
          </div>
          {_renderControl()}
          <div className="rowCenter">
            <Carousel
              indicators={false}
              controls={false}
              activeIndex={showingCardIndex}
              onSelect={_handleSelectShowingCard}
              interval={null}
            >
              {listLinkCard?.map((item) => (
                <Carousel.Item key={item.id}>
                  {_renderCardItem(item)}
                </Carousel.Item>
              ))}
            </Carousel>
          </div>
          {_renderDots()}

          {_renderAction()}
        </div>
      </div>
      <div className={"bottomButtonBlock"}>
        <Button
          variant="primary"
          onClick={_handleAddNewCard}
          className="fullWidth"
        >
          {t("add-new-card")}
        </Button>
      </div>
    </div>
  );
};

export default LinkCardDetail;
