import { useCallback, useEffect, useState } from "react";
import Toolbar from "src/components/Toolbar";
import { useTranslation } from "react-i18next";
import styles from "./CashinStart.module.scss";
import cashinGuide from "src/assets/images/cashin-guide.png";
import linkImg from "src/assets/images/link.svg";
import walletImg from "src/assets/images/wallet.svg";
import chevronRightImg from "src/assets/images/chevron-right.svg";
import noBankAccountImg from "src/assets/images/no-bank-account.svg";
import Button from "react-bootstrap/Button";
import { useHistory } from "react-router-dom";
import { getAccounts, getBanks, getConfig } from "src/api";
import Account from "src/models/Account";
import AccountService from "src/services/AccountService";
import Utils from "src/utils/Utils";
import Image from "src/components/Image";

const CashinStart = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [linkedAccountList, setLinkedAccountList] = useState<Account[]>();
  const [isNoAccount, setIsNoAcount] = useState<boolean>(false);
  const _handleLink = () => {
    console.log("_handleLink");
    history.push("/add-link-choose-bank");
  };

  const _loadLinkAccount = useCallback(async () => {
    const savedLinkedAccount = AccountService.getLinkedAccount();
    if (savedLinkedAccount && savedLinkedAccount.length > 0) {
      setLinkedAccountList(savedLinkedAccount);
      setLoading(false);
    }
    try {
      const accountListRes = await getAccounts(null);
      console.log("accountListRes", accountListRes);
      const accountList = accountListRes?.data;
      const linkedAccountList = accountList?.filter(
        (item) => item?.nbLinks && item?.nbLinks > 0
      );
      if (!accountList || accountList.length === 0) {
        setIsNoAcount(true);
      }
      setLinkedAccountList(linkedAccountList);
      AccountService.saveListAccount(accountList);
      AccountService.saveLinkedAccount(linkedAccountList);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log("Load link account err", err);
    }
  }, []);

  const _loadBanks = useCallback(async () => {
    try {
      const bankListRes = await getBanks();
      console.log("bankListRes", bankListRes);
      AccountService.saveBanks(bankListRes?.data);
    } catch (err) {
      console.log("Load bank err", err);
    }
  }, []);
  const _loadConfig = useCallback(async () => {
    try {
      const configRes = await getConfig();
      console.log("configRes", configRes);
      AccountService.saveConfig(configRes?.data);
    } catch (err) {
      console.log("Load bank err", err);
    }
  }, []);

  const _clearFormInfo = () => {
    AccountService.clearChargeInfo();
    AccountService.clearSelectedBank();
    AccountService.clearLinkInfo();
  };

  useEffect(() => {
    _loadLinkAccount();
    _loadBanks();
    _loadConfig();
    _clearFormInfo();
  }, [_loadLinkAccount, _loadBanks, _loadConfig]);

  const _renderNoAccount = () => {
    return (
      <div className={`${styles.emptyStateContainer} screenScrollContainer`}>
        <Image
          src={noBankAccountImg}
          className={styles.cashinGuideImg}
          alt="Cash in guide"
        />
        <div className="title18 text-center mb-4">{t("have-no-account")}</div>
        <div className="body14 textGray text-center mb48">
          {t("no-account-detail")}
        </div>
        <Button variant="primary" onClick={_handleBack}>
          {t("close")}
        </Button>
      </div>
    );
  };

  const _renderEmptyState = () => {
    return (
      <div className={`${styles.emptyStateContainer} screenScrollContainer`}>
        <Image
          src={cashinGuide}
          className={styles.cashinGuideImg}
          alt="Cash in guide"
        />
        <div className="title18 text-center mb-4">
          {t("link-atm-card-with-mbbank")}
        </div>
        <div className="body14 textGray text-center mb48">
          {t("link-atm-card-guide")}
        </div>
        <Button variant="primary" onClick={_handleLink}>
          {t("link-now")}
        </Button>
      </div>
    );
  };

  const _goToLinkAndUnLink = () => {
    history.push("/link-and-unlink");
  };

  const _goToCharge = () => {
    history.push("/charge");
  };

  const _renderMenu = () => {
    return (
      <div className={`${styles.menuContainer} screenScrollContainer`}>
        <div className={"rowMenuItem mb-3"} onClick={_goToLinkAndUnLink}>
          <Image src={linkImg} className={styles.menuImg} alt="Link" />
          <div className={styles.menuName}>{t("link-and-unlink-card")}</div>
          <Image
            src={chevronRightImg}
            className={styles.chevronRight}
            alt="Chevron right"
          />
        </div>
        <div className={"rowMenuItem"} onClick={_goToCharge}>
          <Image src={walletImg} className={styles.menuImg} alt="Wallet" />
          <div className={styles.menuName}>{t("cashin-to-account")}</div>
          <Image
            src={chevronRightImg}
            className={styles.chevronRight}
            alt="Cashin to account"
          />
        </div>
      </div>
    );
  };

  const _handleBack = () => {
    Utils.closeWebview();
  };

  return (
    <div className="screenContainer">
      <Toolbar title={t("cashin")} onBack={_handleBack} />
      {loading ? (
        <div />
      ) : isNoAccount ? (
        _renderNoAccount()
      ) : !linkedAccountList || linkedAccountList.length === 0 ? (
        _renderEmptyState()
      ) : (
        _renderMenu()
      )}
    </div>
  );
};

export default CashinStart;
