import UserInfo from "src/models/UserInfo";

class UserInfoService {
  static setInfo(
    accessToken: string,
    refreshToken: string,
    loginToken?: string,
    sellerInfo?: string
  ) {
    sessionStorage.setItem("access_token", accessToken);
    sessionStorage.setItem("refresh_token", refreshToken);
    if (loginToken) {
      sessionStorage.setItem("login_token", loginToken);
    }
    if (sellerInfo) {
      sessionStorage.setItem("seller_info", JSON.stringify(sellerInfo));
    }
  }

  static getAccessToken(): string | null {
    return sessionStorage.getItem("access_token");
  }

  static getRefreshToken(): string | null {
    return sessionStorage.getItem("refresh_token");
  }

  static setLoginToken(loginToken: string) {
    sessionStorage.setItem("login_token", loginToken);
  }

  static getLoginToken = (): string | null => {
    return sessionStorage.getItem("login_token");
  };

  static clearInfo() {
    sessionStorage.removeItem("seller_info");
    sessionStorage.removeItem("access_token");
    sessionStorage.removeItem("refresh_token");
  }

  static setUserInfo(userInfo) {
    sessionStorage.setItem("user_info", JSON.stringify(userInfo));
  }

  static getUserInfo(): UserInfo | null {
    const userInfoStr = sessionStorage.getItem("user_info");
    if (!userInfoStr) return null;
    try {
      const userInfo: UserInfo = JSON.parse(userInfoStr);
      return userInfo;
    } catch (err) {
      console.log("Parse UserInfo err", err);
      return null;
    }
  }
}

export default UserInfoService;
