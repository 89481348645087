import Toolbar from "src/components/Toolbar";
import { useTranslation } from "react-i18next";
import styles from "./ChargeConfirm.module.scss";
import Button from "react-bootstrap/Button";
import Utils from "src/utils/Utils";
import AccountService from "src/services/AccountService";
import { requestCashin } from "src/api";
import UiService from "src/services/UiService";

const ChargeConfirm = () => {
  const { t } = useTranslation();

  const _handleConfirmCharge = async () => {
    console.log("_handleConfirmCharge");
    try {
      UiService.showLoading();
      const requestCashinRes = await requestCashin(
        AccountService.getChargeInfo()?.card?.id || "",
        "" + AccountService.getChargeInfo()?.amount,
        `${window?.config?.webhost || ""}/charge-result`
      );
      UiService.hideLoading();
      console.log("requestCashinRes", requestCashinRes);
      window.location.href = requestCashinRes?.data?.confirmUrl;
    } catch (err: any) {
      console.log("Request cash in err", err);
      UiService.hideLoading();
      UiService.hideLoading();
      if (err?.response?.status === 400) {
        console.log("Err response", err?.response);
        UiService.openPopup({
          content: t(
            `err-server-${err?.response?.data?.error_code}`,
            t("general_error")
          ),
          onlyOkButton: true,
          okTitle: t("close"),
          onOk: () => {
            UiService.closePopup();
          },
        });
      }
    }
  };

  const _getDisplayCardSource = () => {
    if (!AccountService.getChargeInfo()?.card) return "";
    if (AccountService.getChargeInfo()?.card?.sourceBank?.code) {
      return `${AccountService.getChargeInfo()?.card?.sourceBank?.code} **${
        AccountService.getChargeInfo()?.card?.sourceCardEnding
      }`;
    }
    return `**${AccountService.getChargeInfo()?.card?.sourceCardEnding}`;
  };

  return (
    <div className="screenContainer">
      <Toolbar title={t("cashin-to-account")} />
      <div className={`${styles.container} screenScrollContainer`}>
        <div className="title20 mb18">{t("transaction-info")}</div>
        <div className={styles.infoContainer}>
          <div className={styles.infoRow}>
            <div className="textGray body14 flex1">{t("money-source")}</div>
            <div className="textBlack title14">{_getDisplayCardSource()}</div>
          </div>
          <div className={styles.infoRow}>
            <div className="textGray body14 flex1">{t("money-amount")}</div>
            <div className="textBlack title18">
              {Utils.formatMoney(AccountService.getChargeInfo()?.amount)}
              {t("vnd")}
            </div>
          </div>
          <div className={styles.infoRow}>
            <div className="textGray body14 flex1">{t("service-fee")}</div>
            <div className="textSuccess title14">{t("free")}</div>
          </div>
          <div className={styles.infoRowWithoutBorder}>
            <div className="textGray body14 flex1">{t("receive-account")}</div>
            <div className="textBlack title14">
              {Utils.getAccountNoDisplay(
                AccountService.getChargeInfo()?.account?.accountNo
              )}
            </div>
          </div>
        </div>
        <div className="bottomButtonSpacer" />
      </div>
      <div className={"bottomButtonBlock"}>
        <Button
          variant="primary"
          onClick={_handleConfirmCharge}
          className="fullWidth"
        >
          {t("confirm")}
        </Button>
      </div>
    </div>
  );
};

export default ChargeConfirm;
