import { HorizontalStepperItem } from "./components/HorizontalStepper";
import documentAddImg from "./assets/images/document-add.svg";
import questionMarkCircleImg from "./assets/images/question-mark-circle.svg";
import informationCircleImg from "./assets/images/information-circle.svg";
// Preload image
import noInternetImg from "src/assets/images/no-internet.png";
import chevronRightImg from "src/assets/images/chevron-right.svg";
import mbImg from "src/assets/images/mb.svg";
import chevronLeft from "src/assets/images/chevron-left.svg";
import searchImg from "src/assets/images/search.svg";
import plusImg from "src/assets/images/plus-blue.svg";
import shieldWarningImg from "src/assets/images/shield-warning.svg";
import infoBlueImg from "src/assets/images/info-circle-blue.svg";
import carretDownImg from "src/assets/images/caret-down-fill.svg";
import calendarImg from "src/assets/images/calendar.svg";
import plusCircleImg from "src/assets/images/plus-circle-primary.svg";
import linkImg from "src/assets/images/link.svg";
import walletImg from "src/assets/images/wallet.svg";
import linkSuccessImg from "src/assets/images/link-card-success.svg";
import linkFailedImg from "src/assets/images/link-card-failed.svg";
import unlinkSuccessImg from "src/assets/images/unlink-card-success.svg";
import unlinkFailedImg from "src/assets/images/unlink-card-failed.svg";
import chargeSuccessImg from "src/assets/images/charge-success.svg";
import chargeFailedImg from "src/assets/images/charge-failed.svg";
import noBankImg from "src/assets/images/no-bank.svg";
import closeGrayImg from "src/assets/images/close-gray.svg";
import closeWhiteImg from "src/assets/images/close-white.svg";
import noBankAccountImg from "src/assets/images/no-bank-account.svg";
import errorLoginImg from "src/assets/images/error-login.svg";
import roundArrowLeft from "src/assets/images/round-left-arrow.svg";
import roundArrowRight from "src/assets/images/round-right-arrow.svg";
import plusGrayImg from "src/assets/images/plus-gray.svg";
import linkGrayImg from "src/assets/images/link-gray.svg";
import napasImg from "src/assets/images/napas.png";
import cardImg from "src/assets/images/card.png";

export const BUY_INSURANCE_STEP_DATA: Array<HorizontalStepperItem> = [
  {
    name: "fill-personal-info",
    icon: documentAddImg,
  },
  {
    name: "health-question",
    icon: questionMarkCircleImg,
  },
  {
    name: "confirm-info",
    icon: informationCircleImg,
  },
];

export const OTP_LENGTH: number = 6;
export const OTP_TIME = 60; // seconds;
export const REPLACE_IDENTIFIER = "*****";

export const VN_COUNTRY_CODE: string = "VN";

export enum ProductCode {
  CANCER = "CANCER",
  SPA = "SPA",
}

export enum PromotionStatus {
  HAPPENING = "HAPPENING",
  FINISHED = "FINISHED",
}

export enum HomeNewsPromotionType {
  PROMOTION = "PROMOTION",
  NEWS = "EVENT_NEW",
}

export enum ProductType {
  PRODUCT_SUPPORT = "PRODUCT_SUPPORT",
  PRODUCT_MAIN = "PRODUCT_MAIN",
}
export enum Gender {
  MALE = "MALE",
  FEMALE = "FEMALE",
}
export const GenderDisplayMap = {
  [Gender.MALE]: "male",
  [Gender.FEMALE]: "female",
};
// export enum CardType {
//   PASSPORT = "FS0002",
//   CMND = "ZMBL02",
//   CCCD = "ZMBL03",
//   CMTQD = "ZMBL18",
// }

export enum CardType {
  PASSPORT = "PASSPORT",
  CMND = "CMND",
  CCCD = "CCCD",
  CMTQD = "CMTQD",
}

export const CardTypeSortDisplayMap = {
  [CardType.PASSPORT]: "passport",
  [CardType.CMND]: "cmnd-sort",
  [CardType.CCCD]: "cccd-sort",
  [CardType.CMTQD]: "military-card-sort",
};

export const CARD_TYPE_VALUES = [
  { name: "passport", value: CardType.PASSPORT },
  { name: "cmnd", value: CardType.CMND },
  { name: "cccd", value: CardType.CCCD },
  { name: "military-card", value: CardType.CMTQD },
];
export const PAGE_SIZE = 10;

export const FREE_CANCER_SUM_ASSURED = 100000000;
export const CANCER_URL =
  "https://www.mbageas.life/san-pham/bao-hiem-ung-dung-song-khoe";
export const MBAGEAS_LIFE_URL = "https://www.mbageas.life/";
export const INSURANCE_PAYMENT_GUIDE_URL =
  "https://mbageas.life/page/dong-phi-bao-hiem";
export const CARD_NUMBER_MIN_LENGTH = 16;
export const CARD_NUMBER_START = "9704";
export const CARD_NUMBER_MAX_LENGTH = 19;
export const CARD_NUMBER_DISPLAY_MAX_LENGTH = 23;
export const PRELOAD_IMAGES = [
  noInternetImg,
  chevronRightImg,
  mbImg,
  chevronLeft,
  searchImg,
  plusImg,
  shieldWarningImg,
  infoBlueImg,
  carretDownImg,
  calendarImg,
  plusCircleImg,
  linkImg,
  walletImg,
  linkSuccessImg,
  linkFailedImg,
  chargeSuccessImg,
  chargeFailedImg,
  unlinkSuccessImg,
  unlinkFailedImg,
  noBankImg,
  closeGrayImg,
  closeWhiteImg,
  noBankAccountImg,
  errorLoginImg,
  roundArrowLeft,
  roundArrowRight,
  plusGrayImg,
  linkGrayImg,
  napasImg,
  cardImg,
];

export enum ROOT_LOCATION_ACTION {
  CHARGE = "CHARGE",
}
