import Toolbar from "src/components/Toolbar";
import { useTranslation } from "react-i18next";
import styles from "./RemoveLinkResult.module.scss";
import unlinkSuccessImg from "src/assets/images/unlink-card-success.svg";
import unlinkFailedImg from "src/assets/images/unlink-card-failed.svg";
import Button from "react-bootstrap/Button";
import { useHistory, useLocation } from "react-router-dom";
import qs from "qs";
import Utils from "src/utils/Utils";
import HighlightText from "src/components/HighlightText";
import Image from "src/components/Image";

const RemoveLinkResult = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
  const accountNo = queryParams?.accountNo;
  const isSuccess: boolean = queryParams?.success === "true";
  const displayCard = queryParams?.displayCard;
  console.log("queryParams?.success", isSuccess, typeof isSuccess, displayCard);

  const _handleBackMainScreen = () => {
    history.push("/");
  };

  const _handleGoBack = () => {
    history.goBack();
  };

  const _renderSuccess = () => {
    return (
      <>
        <Image
          src={unlinkSuccessImg}
          className={styles.img}
          alt="Remove success"
        />
        <div className="title18 text-center mb-4">
          {t("remove-link-card-success")}
        </div>
        <div className="text-center mb96 prewrap">
          <HighlightText
            text={t("remove-link-card-success-detail")}
            replaceText={[displayCard]}
            className={"body14 textGray prewrap"}
            highlightClassName={"w700"}
          />
        </div>
        <div className="bottomButtonBlock2">
          <Button
            variant="primary"
            className="fullWidth"
            onClick={_handleBackMainScreen}
          >
            {t("finish")}
          </Button>
        </div>
      </>
    );
  };

  const _renderFailed = () => {
    return (
      <>
        <Image
          src={unlinkFailedImg}
          className={styles.img}
          alt="Remove failed"
        />
        <div className="title18 text-center mb-4">
          {t("remove-link-card-failed")}
        </div>
        <div className="text-center mb96">
          <HighlightText
            text={t("remove-link-card-failed-detail")}
            replaceText={[displayCard]}
            className={"body14 textGray"}
            highlightClassName={"w700"}
          />
        </div>
        <div className="bottomButtonBlock2">
          <Button
            variant="primary"
            className="fullWidth mb-3"
            onClick={_handleGoBack}
          >
            {t("close")}
          </Button>
        </div>
      </>
    );
  };

  return (
    <div className="screenContainer">
      <Toolbar title={Utils.getAccountNoDisplay(accountNo)} showBack={false} />
      <div className={`${styles.container} screenScrollContainer`}>
        {isSuccess ? _renderSuccess() : _renderFailed()}
      </div>
    </div>
  );
};

export default RemoveLinkResult;
