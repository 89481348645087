import styles from "./CardSourceList.module.scss";
import { useTranslation } from "react-i18next";
import plusImg from "src/assets/images/plus-blue.svg";
import chevronRightImg from "src/assets/images/chevron-right.svg";
import radioCheckedImg from "src/assets/images/radio-checked.svg";
import radioDefaultImg from "src/assets/images/radio-default.svg";
import LinkCard from "src/models/LinkCard";
import noBankImg from "src/assets/images/no-bank.svg";
import Image from "src/components/Image";

interface CardSourceListProps {
  data?: LinkCard[];
  value?: string;
  onChange?: (value: string) => void;
  onAddNewCard?: () => void;
}

const CardSourceList = ({
  data,
  value,
  onChange,
  onAddNewCard,
}: CardSourceListProps) => {
  const { t } = useTranslation();

  const _handleClickItem = (item: LinkCard) => {
    if (item.id !== value && onChange) {
      onChange(item.id);
    }
  };

  const _renderItem = (item: LinkCard, index: number) => {
    const isSelected = item.id === value;
    return (
      <div
        className={isSelected ? styles.bankItemSelected : styles.bankItem}
        onClick={() => _handleClickItem(item)}
        key={item.id}
      >
        <Image
          src={item?.sourceBank?.photo || noBankImg}
          alt={item?.sourceBank?.code}
          className={styles.bankImg}
        />
        <div className="flex1 body14 w600 textBlack">
          {item?.sourceBank?.code || t("atm-card")} **{item.sourceCardEnding}
        </div>
        {isSelected ? (
          <Image
            src={radioCheckedImg}
            alt="Radio checked"
            className={styles.radioImg}
          />
        ) : (
          <Image
            src={radioDefaultImg}
            alt="Radio default"
            className={styles.radioImg}
          />
        )}
      </div>
    );
  };

  const _handleAddNewCard = () => {
    if (onAddNewCard) {
      onAddNewCard();
    }
  };

  return (
    <div>
      <div className="title20 mb18">{t("money-source")}</div>
      {data?.map(_renderItem)}
      <div className={styles.addNewCardItem} onClick={_handleAddNewCard}>
        <div className={styles.plusImageContainer}>
          <Image src={plusImg} alt="Plus" className={styles.plusImg} />
        </div>
        <div className="flex1 body14 w600 textBlack">{t("add-new-card")}</div>
        <Image
          src={chevronRightImg}
          className={styles.chevronRight}
          alt="Chevron right"
        />
      </div>
    </div>
  );
};

export default CardSourceList;
