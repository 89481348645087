import { Switch, Route, useLocation } from "react-router-dom";
import CashinStart from "./pages/CashinStart";
import LinkAndUnlink from "./pages/LinkAndUnlink";
import AddLinkChooseBank from "./pages/AddLinkChooseBank";
import AddLinkCardForm from "./pages/AddLinkCardForm";
import LinkCardResult from "./pages/LinkCardResult";
import Charge from "./pages/Charge";
import ChargeConfirm from "./pages/ChargeConfirm";
import ChargeResult from "./pages/ChargeResult";
import LinkCardDetail from "./pages/LinkCardDetail";
import RemoveLinkResult from "./pages/RemoveLinkResult";
import { useCallback, useEffect, useState } from "react";
import UserInfoService from "./services/UserInfoService";
import { login, getUserInfo } from "src/api";
import qs from "qs";
import LoginFailed from "./components/LoginFailed";

const AuthRoute = ({ children, ...rest }) => {
  const location = useLocation();
  const [logining, setLogining] = useState<boolean>(true);
  const [loginFailed, setLoginFailed] = useState<boolean>(false);

  const _loginByToken = useCallback(async () => {
    let queryUrl = location.search;
    const queryParams = qs.parse(queryUrl, { ignoreQueryPrefix: true });
    const loginToken = queryParams?.loginToken as string;
    console.log("loginToken", loginToken);

    if (!loginToken) {
      if (!UserInfoService.getAccessToken()) {
        setLoginFailed(true);
      }
      setLogining(false);
      return;
    }

    if (
      !UserInfoService.getAccessToken() ||
      loginToken !== UserInfoService.getLoginToken()
    ) {
      try {
        setLogining(true);
        const loginRes = await login(loginToken);
        console.log("Login Res", loginRes);
        UserInfoService.setInfo(
          loginRes?.data?.access_token,
          loginRes?.data?.refresh_token,
          loginToken
        );
        const userInfoRes = await getUserInfo();
        console.log("userInfoRes", userInfoRes);
        UserInfoService.setUserInfo(userInfoRes.data);
        setLogining(false);
      } catch (err: any) {
        console.log("Login err", err, err?.response);

        setLoginFailed(true);
        setLogining(false);
      }
    } else {
      setLogining(false);

      return;
    }
  }, [location]);

  useEffect(() => {
    _loginByToken();
  }, [location, _loginByToken]);
  if (logining)
    return (
      <div className="loadingSmall-spinner-rolling">
        <div className="loadingSmall">
          <div></div>
        </div>
      </div>
    );
  if (loginFailed) {
    return <LoginFailed />;
  }
  return <Route {...rest} render={({ location }) => children} />;
};

const AppRoutes = () => {
  return (
    <Switch>
      <AuthRoute exact path="/">
        <CashinStart />
      </AuthRoute>
      <AuthRoute exact path="/link-and-unlink">
        <LinkAndUnlink />
      </AuthRoute>
      <AuthRoute exact path="/add-link-choose-bank">
        <AddLinkChooseBank />
      </AuthRoute>
      <AuthRoute exact path="/add-link-card-form">
        <AddLinkCardForm />
      </AuthRoute>
      <AuthRoute exact path="/link-card-result">
        <LinkCardResult />
      </AuthRoute>
      <AuthRoute exact path="/link-card-detail">
        <LinkCardDetail />
      </AuthRoute>
      <AuthRoute exact path="/remove-link-result">
        <RemoveLinkResult />
      </AuthRoute>

      <AuthRoute exact path="/charge">
        <Charge />
      </AuthRoute>
      <AuthRoute exact path="/charge-confirm">
        <ChargeConfirm />
      </AuthRoute>
      <AuthRoute exact path="/charge-result">
        <ChargeResult />
      </AuthRoute>
    </Switch>
  );
};

export default AppRoutes;
