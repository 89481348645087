import searchImg from "src/assets/images/search.svg";
import closeGrayImg from "src/assets/images/close-gray.svg";
import styles from "./SearchInput.module.scss";
import Image from "src/components/Image";
interface SearchInputProps {
  placeholder?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  value?: string;
  maxLength?: number;
  onClear?: () => void;
}

const SearchInput = (props: SearchInputProps) => {
  const { value, onChange, onClear, ...passProps } = props;

  const _handleClear = () => {
    onClear && onClear();
  };

  return (
    <div className={styles.searchInputContainer}>
      <input
        value={value}
        onChange={onChange}
        className={styles.input}
        {...passProps}
      />
      {!value ? (
        <Image src={searchImg} alt="search" className={styles.searchImg} />
      ) : (
        <Image
          src={closeGrayImg}
          alt="clear"
          className={styles.searchImg}
          onClick={_handleClear}
        />
      )}
    </div>
  );
};

export default SearchInput;
