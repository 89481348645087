import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./i18n";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyBmwuLG1tqVtQtq304xQ8LgMG0UQC50X04",
  authDomain: "cashinecom-prod.firebaseapp.com",
  projectId: "cashinecom-prod",
  storageBucket: "cashinecom-prod.appspot.com",
  messagingSenderId: "353902519817",
  appId: "1:353902519817:web:930a4cb26874bf82407ad8",
  measurementId: "G-8MKLY659T5",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function noop() {}

if (process.env.NODE_ENV !== "development") {
  console.log = noop;
  console.warn = noop;
  console.error = noop;
}

declare global {
  interface Window {
    ReactNativeWebView?: any;
    config?: any;
  }
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
