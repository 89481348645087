import chevronLeft from "src/assets/images/chevron-left.svg";
import { useHistory } from "react-router-dom";
import { useCallback } from "react";
import styles from "./Toolbar.module.scss";
import Image from "src/components/Image";
type ToolbarProps = {
  title: string;
  onBack?: () => void;
  showBack?: boolean;
};

const Toolbar = (props: ToolbarProps) => {
  const { title, onBack, showBack = true } = props;
  const history = useHistory();

  const _handleBack = useCallback(() => {
    if (onBack) {
      onBack();
      return;
    }
    if (history.length >= 2) {
      history.goBack();
    }
  }, [history, onBack]);

  return (
    <div className={`${styles.toolbarContainer} toolbarContainerTop`}>
      <div className={styles.toolbar}>
        {!!showBack ? (
          <div onClick={_handleBack}>
            <Image
              src={chevronLeft}
              className={styles.chevronLeftImg}
              alt="chevron-left"
            />
          </div>
        ) : (
          <div className={styles.backIconPlacehilder}></div>
        )}
        <div className={styles.titleContainer}>
          <div className="toolbarTitle textOnPrimary">{title}</div>
        </div>
      </div>
    </div>
  );
};

export default Toolbar;
