import Toolbar from "src/components/Toolbar";
import { useTranslation } from "react-i18next";
import styles from "./LinkCardResult.module.scss";
import linkSuccessImg from "src/assets/images/link-card-success.svg";
import linkFailedImg from "src/assets/images/link-card-failed.svg";
import Button from "react-bootstrap/Button";
import { useHistory, useLocation } from "react-router-dom";
import qs from "qs";
import { getTransactionDetail } from "src/api";
import { useCallback, useEffect, useState } from "react";
import TransactionDetail from "src/models/TransactionDetail";
import HighlightText from "src/components/HighlightText";
import Image from "src/components/Image";

const LinkCardResult = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const [transactionDetail, setTransactionDetail] =
    useState<TransactionDetail>();
  //localhost:3000/link-card-success?success=true&type=LINK&transactionId=1453944725521309696&linkId=1453944725450006528
  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
  const isSuccess: boolean = queryParams?.success === "true";
  const transactionId = queryParams?.transactionId;

  console.log("queryParams?.success", isSuccess, typeof isSuccess);

  const _loadTransactionDetail = useCallback(async () => {
    if (!transactionId) return;
    try {
      const transactionDetailRes = await getTransactionDetail(transactionId);
      console.log("transactionDetailRes", transactionDetailRes);
      setTransactionDetail(transactionDetailRes?.data);
    } catch (err) {
      console.log("Load transaction detail err", err);
    }
  }, [transactionId]);

  useEffect(() => {
    _loadTransactionDetail();
  }, [_loadTransactionDetail]);

  const _handleBackMainScreen = () => {
    history.push("/");
  };

  const _handleRetryLink = () => {
    history.push("/");
    history.push("/add-link-choose-bank");
    history.push("/add-link-card-form");
  };

  const _handleCharge = () => {
    history.push("/");
    history.push("/charge");
  };

  const _renderSuccess = () => {
    return (
      <>
        <Image src={linkSuccessImg} className={styles.img} alt="Link success" />
        <div className="title18 text-center mb-4">{t("link-card-success")}</div>
        <div className="text-center mb96 prewrap">
          <HighlightText
            text={t("link-card-success-detail")}
            replaceText={[_getDisplayCard()]}
            className={"body14 textGray prewrap"}
            highlightClassName={"w700"}
          />
        </div>
        <div className="bottomButtonBlock2">
          <Button
            variant="primary"
            className="fullWidth mb-3"
            onClick={_handleBackMainScreen}
          >
            {t("finish")}
          </Button>
          <Button
            variant="outline-primary"
            className="fullWidth"
            onClick={_handleCharge}
          >
            {t("cashin-to-account")}
          </Button>
        </div>
      </>
    );
  };

  const _getDisplayCard = () => {
    return transactionDetail?.link?.sourceBank?.code
      ? `${transactionDetail?.link?.sourceBank?.code} **${transactionDetail?.link.sourceCardEnding}`
      : `**${transactionDetail?.link.sourceCardEnding}`;
  };

  const _getFailedReasonText = (): string => {
    return t(
      `err-link-${transactionDetail?.failReason || ""}`,
      t("err-link-unknown")
    );
  };

  const _renderFailed = () => {
    return (
      <>
        <Image src={linkFailedImg} className={styles.img} alt="Link success" />
        <div className="title18 text-center mb-4">{t("link-card-failed")}</div>
        <div className="body14 textGray text-center mb96">
          <HighlightText
            text={_getFailedReasonText()}
            replaceText={[_getDisplayCard()]}
            className={"body14 textGray"}
            highlightClassName={"w700"}
          />
        </div>
        <div className="bottomButtonBlock2">
          <Button
            variant="primary"
            className="fullWidth mb-3"
            onClick={_handleBackMainScreen}
          >
            {t("finish")}
          </Button>
          <Button
            variant="outline-primary"
            className="fullWidth"
            onClick={_handleRetryLink}
          >
            {t("retry")}
          </Button>
        </div>
      </>
    );
  };

  return (
    <div className="screenContainer">
      <Toolbar title={t("add-new-link-card")} showBack={false} />
      <div className={`${styles.container} screenScrollContainer`}>
        {isSuccess ? _renderSuccess() : _renderFailed()}
      </div>
    </div>
  );
};

export default LinkCardResult;
