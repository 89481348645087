import React, { useRef } from "react";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import InputGroup from "react-bootstrap/InputGroup";
import closeGrayImg from "src/assets/images/close-gray.svg";
import styles from "./Input.module.scss";
import Image from "src/components/Image";
interface InputProps {
  label?: string;
  required?: boolean;
  placeholder?: string;
  leftIcon?: string;
  errorText?: string;
  maxLength?: number;
  value?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onSubmit?: React.FormEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  showDefautErrorIcon?: boolean;
  disabled?: boolean;
  rightLabelComponent?: React.ReactNode;
  labelHelperIcon?: React.ReactNode;
  className?: string;
  inputMode?:
    | "text"
    | "search"
    | "none"
    | "tel"
    | "url"
    | "email"
    | "numeric"
    | "decimal"
    | undefined;
  rightComponent?: React.ReactNode;
  onClear?: () => void;
}

const Input = ({
  label,
  required,
  leftIcon,
  errorText,
  showDefautErrorIcon = true,
  rightLabelComponent,
  labelHelperIcon,
  className,
  rightComponent,
  onClear,
  ...props
}: InputProps) => {
  const inputRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  const _handleClear = () => {
    onClear && onClear();
    inputRef?.current?.focus();
  };

  const _handleKeyUp = (event) => {
    if (event.key === "Enter") {
      inputRef?.current?.blur();
    }
  };

  return (
    <div className={styles.inputContainer}>
      <Form.Label className="fullWidth">
        <div className="rowStart fullWidth">
          <div className="rowStart flex1 body14 w600 textGray">
            {label}
            {!!required && <span className="required"> *</span>}
            {labelHelperIcon && labelHelperIcon}
          </div>
          {rightLabelComponent && rightLabelComponent}
        </div>
      </Form.Label>
      <InputGroup hasValidation={!!errorText}>
        {leftIcon && (
          <InputGroup.Text className={!!errorText ? "error" : ""}>
            <Image src={leftIcon} width={24} height={24} alt={leftIcon} />
          </InputGroup.Text>
        )}

        <FormControl
          {...props}
          style={leftIcon ? { borderLeftColor: "transparent" } : undefined}
          isInvalid={!!errorText}
          className={
            (showDefautErrorIcon ? "" : "noErrorIcon") +
            (className ? " " + className : "")
          }
          enterKeyHint="done"
          ref={inputRef}
          onKeyUp={_handleKeyUp}
        />
        {!!errorText && (
          <Form.Control.Feedback type="invalid">
            {errorText}
          </Form.Control.Feedback>
        )}
      </InputGroup>
      {onClear && props.value ? (
        <div className={styles.rightComponentContainer}>
          <Image
            src={closeGrayImg}
            alt="clear"
            className={styles.searchImg}
            onClick={_handleClear}
          />
        </div>
      ) : (
        rightComponent && (
          <div className={styles.rightComponentContainer}>{rightComponent}</div>
        )
      )}
    </div>
  );
};

export default Input;
