import { get, post } from "./common";

export const login = (token: string) => {
  return post({ url: "/authentication/miniapp/login", params: { token } });
};

export const refreshToken = (refreshToken: string) => {
  return post({
    url: "/authentication/miniapp/refresh-token",
    params: { refreshToken },
  });
};

export const getUserInfo = () => {
  return get({ url: "/authentication/miniapp/userinfo" });
};

export const getBanks = () => {
  return get({ url: "/api/miniapp/bank" });
};

export const getAccounts = (hasLink: boolean | null) => {
  return get({ url: "/api/miniapp/account", params: { hasLink } });
};

export const getLinks = (accountNo: string) => {
  return get({ url: "/api/miniapp/link", params: { accountNo } });
};

export const requestLinkCard = (params: any) => {
  return post({ url: "/api/miniapp/link", params });
};

export const requestUnlinkCard = (linkId: string) => {
  return post({ url: `/api/miniapp/link/${linkId}/unlink` });
};

export const requestCashin = (
  linkId: string,
  amount: string,
  resultUrl: string
) => {
  return post({
    url: `/api/miniapp/link/${linkId}/cashin`,
    params: { amount, resultUrl },
  });
};

export const getTransactionDetail = (transactionId: string) => {
  return get({ url: `/api/miniapp/transaction/${transactionId}` });
};

export const getConfig = () => {
  return get({ url: "/api/miniapp/config" });
};
