import { useCallback, useEffect, useState } from "react";
import Toolbar from "src/components/Toolbar";
import { useTranslation } from "react-i18next";
import styles from "./Charge.module.scss";
import { useHistory, useLocation } from "react-router-dom";
import Input from "src/components/Input";
import Utils from "src/utils/Utils";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Container } from "react-bootstrap";
import CardSourceList from "src/components/CardSourceList";
import Button from "react-bootstrap/Button";
import BankAccountItemSelector from "src/components/BankAccountItemSelector";
import { getAccounts, getLinks } from "src/api";
import Account from "src/models/Account";
import LinkCard from "src/models/LinkCard";
import AccountService from "src/services/AccountService";
import qs from "qs";
const Charge = () => {
  const defaultAmount = AccountService.getChargeInfo()?.amount
    ? "" + AccountService.getChargeInfo()?.amount
    : "0";
  const history = useHistory();
  const location = useLocation();
  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
  const { t } = useTranslation();
  const [money, setMoney] = useState<string>(defaultAmount);
  const [errMoney, setErrMoney] = useState<string>();
  const [accountList, setAccountList] = useState<Account[]>();
  const [selectAccount, setSelectAccount] = useState<string>(
    queryParams?.accountNo
  );
  const [listLinkCard, setListLinkCard] = useState<LinkCard[]>();
  const [selectCard, setSelectCard] = useState<string>(queryParams?.selectCard);

  const _loadLinkCard = useCallback(
    async (accountNo: string) => {
      try {
        const linksRes = await getLinks(accountNo);
        console.log("linksRes", linksRes);
        const listLinkCardRes = linksRes?.data || [];
        setListLinkCard(listLinkCardRes);
        const indexOfSelectedCard = listLinkCardRes.findIndex(
          (item) => item.id === selectCard
        );
        const indexOfSavedCard = AccountService.getChargeInfo()?.card?.id
          ? listLinkCardRes.findIndex(
              (item) => item.id === AccountService.getChargeInfo()?.card?.id
            )
          : -1;
        const defaultSelectCard =
          (indexOfSelectedCard >= 0 ? selectCard : null) ||
          (indexOfSavedCard >= 0
            ? AccountService.getChargeInfo()?.card?.id
            : null) ||
          linksRes?.data?.[0]?.id;
        setSelectCard(defaultSelectCard);
      } catch (err) {
        console.log("Load link err", err);
      }
    },
    [selectCard]
  );

  const _loadBankAccount = useCallback(async () => {
    try {
      const accountListRes = await getAccounts(true);
      console.log("accountListRes", accountListRes);
      setAccountList(accountListRes?.data || []);
      if (accountListRes?.data?.length > 0) {
        const defaultAccountNo =
          AccountService.getChargeInfo()?.account?.accountNo ||
          accountListRes?.data.find((item) => item.defaultAccount)?.accountNo ||
          accountListRes?.data?.[0].accountNo;
        if (!selectAccount) {
          setSelectAccount(defaultAccountNo);
          _loadLinkCard(defaultAccountNo);
        } else {
          _loadLinkCard(selectAccount);
        }
      }
    } catch (err) {
      console.log("Load link account err", err);
    }
  }, [selectAccount, _loadLinkCard]);

  useEffect(() => {
    AccountService.clearLinkInfo();
    _loadBankAccount();
  }, []);

  const _handleChangeSelectAccount = (value) => {
    setSelectAccount(value);
    _loadLinkCard(value);
  };

  const _handleChangeMoney = (e) => {
    const newValue = e.target.value;
    const caretStart = e.target.selectionStart;
    setMoney(newValue);
    setErrMoney("");
    const newValueFormatted = Utils.formatMoney(newValue);
    const newValueWithoutFormat = newValue.replace(/\D/g, "");
    const previousMoney = Utils.formatMoney(money);
    window.requestAnimationFrame(() => {
      let newCaretStart = caretStart;
      if (
        newCaretStart === previousMoney.length + 1 &&
        newValueFormatted.length > previousMoney.length &&
        newValueWithoutFormat.length % 3 === 1
      ) {
        newCaretStart++;
      } else if (
        newCaretStart < previousMoney.length + 1 &&
        newValueFormatted.length > previousMoney.length
      ) {
        const previousNumOfDotBeforeCaret =
          previousMoney.substring(0, newCaretStart).split(".").length - 1;
        const currentNumOfDotBeforeCaret =
          newValueFormatted.substring(0, newCaretStart).split(".").length - 1;
        if (currentNumOfDotBeforeCaret > previousNumOfDotBeforeCaret) {
          newCaretStart++;
        } else if (currentNumOfDotBeforeCaret < previousNumOfDotBeforeCaret) {
          newCaretStart--;
        }
      }
      e.target.setSelectionRange(newCaretStart, newCaretStart);
    });
  };

  const _handleClickPredefineMoneyItem = (value: number) => {
    setMoney(Utils.formatMoney(value));
  };

  const _renderPredefineMoneyItem = (value: number) => {
    return (
      <div
        className={styles.predefineMoneyItem}
        onClick={() => _handleClickPredefineMoneyItem(value)}
      >
        <div className="body14 w600">{Utils.formatMoney(value)}</div>
      </div>
    );
  };

  const _handleChangeSelectCard = (value) => {
    setSelectCard(value);
  };

  const _isValid = (): boolean => {
    if (!money || !money.trim()) {
      setErrMoney(t("err-amount-required"));
      return false;
    }
    let moneyNumber = +money.replace(/\D/g, "");
    if (
      AccountService.getConfig()?.minCashinAmount &&
      moneyNumber < AccountService.getConfig()!.minCashinAmount!
    ) {
      setErrMoney(
        Utils.getReplaceText(
          t("charge-min-amount"),
          Utils.formatMoney(AccountService.getConfig()?.minCashinAmount)
        )
      );
      return false;
    }
    if (
      AccountService.getConfig()?.maxCashinAmount &&
      moneyNumber > AccountService.getConfig()!.maxCashinAmount!
    ) {
      setErrMoney(
        Utils.getReplaceText(
          t("charge-max-amount"),
          Utils.formatMoney(AccountService.getConfig()?.maxCashinAmount)
        )
      );
      return false;
    }
    return true;
  };

  const _handleCharge = () => {
    console.log("_handleCharge");
    const isValid = _isValid();
    if (!isValid) return;
    const selectAccountObj: Account | undefined = accountList?.find(
      (item) => item.accountNo === selectAccount
    );

    const selectCardObj: LinkCard | undefined = listLinkCard?.find(
      (item) => item.id === selectCard
    );
    if (!selectAccountObj || !selectCardObj) return;
    AccountService.saveChargeInfo({
      account: selectAccountObj,
      card: selectCardObj,
      amount: +money.replace(/\D/g, ""),
    });

    history.push(`/charge-confirm`);
  };

  const _handleAddNewCard = () => {
    history.push(`/add-link-choose-bank?accountNo=${selectAccount}`);
  };

  const _getChargeButtonText = () => {
    if (!money || !money.trim()) {
      return t("charge");
    }
    let moneyNumber = +money.replace(/\D/g, "");
    if (
      AccountService.getConfig()?.minCashinAmount &&
      moneyNumber < AccountService.getConfig()!.minCashinAmount!
    ) {
      return t("charge");
    }
    if (
      AccountService.getConfig()?.maxCashinAmount &&
      moneyNumber > AccountService.getConfig()!.maxCashinAmount!
    ) {
      return t("charge");
    }
    return `${t("charge2")} ${Utils.formatMoney(moneyNumber)}${t("vnd")}`;
  };

  return (
    <div className="screenContainer">
      <Toolbar title={t("cashin-to-account")} />
      <div className="screenScrollContainer">
        <div className={styles.container}>
          <div className="mb18 title20">{t("choose-receive-account")}</div>
          <BankAccountItemSelector
            data={accountList}
            value={selectAccount}
            onChange={_handleChangeSelectAccount}
          />
        </div>
        <div className={styles.mainContent}>
          <div className="mb12">
            <Input
              label={t("input-charge-amount")}
              value={Utils.formatMoney(money)}
              onChange={_handleChangeMoney}
              className={styles.moneyInput}
              errorText={errMoney}
              maxLength={15}
              inputMode={"numeric"}
              placeholder={"0"}
              rightComponent={
                <div className="body16 w600 textPlaceholder">đ</div>
              }
            />
          </div>
          <div className="mb-4">
            <Container fluid>
              <Row className="mb-2">
                <Col className="gx-1">{_renderPredefineMoneyItem(100000)}</Col>
                <Col className="gx-1">{_renderPredefineMoneyItem(200000)}</Col>
                <Col className="gx-1">{_renderPredefineMoneyItem(500000)}</Col>
              </Row>
            </Container>
            <Container fluid>
              <Row>
                <Col className="gx-1">{_renderPredefineMoneyItem(1000000)}</Col>
                <Col className="gx-1">{_renderPredefineMoneyItem(2000000)}</Col>
                <Col className="gx-1">{_renderPredefineMoneyItem(5000000)}</Col>
              </Row>
            </Container>
          </div>
          <CardSourceList
            data={listLinkCard}
            value={selectCard}
            onChange={_handleChangeSelectCard}
            onAddNewCard={_handleAddNewCard}
          />
          <div className="bottomButtonSpacer" />
        </div>
      </div>
      <div className={"bottomButtonBlock"}>
        <Button variant="primary" onClick={_handleCharge} className="fullWidth">
          {_getChargeButtonText()}
        </Button>
      </div>
    </div>
  );
};

export default Charge;
