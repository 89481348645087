import Toolbar from "src/components/Toolbar";
import { useTranslation } from "react-i18next";
import styles from "./ChargeResult.module.scss";
import successImg from "src/assets/images/charge-success.svg";
import failedImg from "src/assets/images/charge-failed.svg";
import Button from "react-bootstrap/Button";
import { useHistory, useLocation } from "react-router-dom";
import Utils from "src/utils/Utils";
import qs from "qs";
import { useCallback, useEffect, useState } from "react";
import { getTransactionDetail } from "src/api";
import TransactionDetail from "src/models/TransactionDetail";
import errorImg from "src/assets/images/error.svg";
import Image from "src/components/Image";
import AccountService from "src/services/AccountService";

const ChargeResult = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
  const isSuccess: boolean = queryParams?.success === "true";
  const transactionId = queryParams?.transactionId;
  const [transactionDetail, setTransactionDetail] =
    useState<TransactionDetail>();

  const _loadTransactionDetail = useCallback(async () => {
    if (!transactionId) return;
    try {
      const transactionDetailRes = await getTransactionDetail(transactionId);
      console.log("transactionDetailRes", transactionDetailRes);
      setTransactionDetail(transactionDetailRes?.data);
    } catch (err) {
      console.log("Load transaction detail err", err);
    }
  }, [transactionId]);

  useEffect(() => {
    _loadTransactionDetail();
  }, [_loadTransactionDetail]);

  const _handleFinish = () => {
    history.push("/");
  };

  const _handleNewTransaction = () => {
    AccountService.clearChargeInfo();
    history.push("/");
    history.push("/charge");
  };

  const _handleRetry = () => {
    history.push("/");
    history.push("/charge");
  };

  // http://localhost:3000/charge-result?success=true&type=CASHIN&transactionId=1455367246112096256&linkId=1454959679263211520

  const _getDisplayCard = () => {
    if (!transactionDetail?.link) return "";
    if (transactionDetail?.link?.sourceBank?.code) {
      return `${transactionDetail?.link?.sourceBank?.code} **${transactionDetail?.link.sourceCardEnding}`;
    }
    return `**${transactionDetail?.link.sourceCardEnding}`;
  };

  const _getFailedReasonText = (): string => {
    if (!transactionDetail) return "";
    if (transactionDetail?.failReason === "transaction-cancelled") {
      return t("err-charge-transaction-cancelled");
    } else if (transactionDetail?.failReason === "unknown") {
      return t("err-charge-unknown");
    } else if (transactionDetail?.failReason === "transaction-timeout") {
      return Utils.getReplaceText(
        t("err-charge-transaction-timeout"),
        _getDisplayCard()
      );
    } else if (transactionDetail?.failReason === "invalid-otp") {
      return Utils.getReplaceText(
        t("err-charge-invalid-otp"),
        _getDisplayCard()
      );
    }

    return t(
      `err-server-${transactionDetail?.failReason || ""}`,
      t("err-charge-unknown")
    );
  };

  return (
    <div className="screenContainer">
      <Toolbar title={t("cashin-to-account")} showBack={false} />
      <div className={`${styles.container} screenScrollContainer`}>
        <Image
          src={isSuccess ? successImg : failedImg}
          className={styles.img}
          alt="Result"
        />
        <div className="title18 text-center mb-4">
          {isSuccess ? t("transaction-success") : t("transaction-failed")}
        </div>
        <div
          className={`title24 text-center mb-4 ${
            isSuccess ? "textSuccess" : "textError"
          }`}
        >
          {transactionDetail?.orderAmount
            ? Utils.formatMoney(transactionDetail?.orderAmount)
            : ""}
          {t("vnd")}
        </div>
        {!isSuccess && (
          <div className={styles.erorrContainer}>
            <Image src={errorImg} className={styles.errorImg} alt="Error" />
            <div className="flex1 body14 textBlack">
              {t("reason")}: {_getFailedReasonText()}
            </div>
          </div>
        )}

        <div className={styles.infoBlock}>
          <div className={styles.infoRow}>
            <div className="textGray body14 flex1">{t("transaction-time")}</div>
            <div className="textBlack title14">
              {transactionDetail?.completedTime
                ? Utils.formatDate(
                    new Date(transactionDetail?.completedTime),
                    t("date-time-format")
                  )
                : ""}
            </div>
          </div>
          {transactionDetail?.napasTransactionId && (
            <div className={styles.infoRow}>
              <div className="textGray body14 flex1">
                {t("transaction-code")}
              </div>
              <div className="textBlack title14">
                {transactionDetail?.napasTransactionId}
              </div>
            </div>
          )}

          <div className={styles.infoRowWithoutBorder}>
            <div className="textGray body14 flex1">{t("money-source")}</div>
            <div className="textBlack title14">{_getDisplayCard()}</div>
          </div>
        </div>
        {isSuccess ? (
          <div className="mb-4 fullWidth">
            <Button
              variant="primary"
              className="fullWidth mb-3"
              onClick={_handleFinish}
            >
              {t("finish")}
            </Button>
            <Button
              variant="outline-primary"
              className="fullWidth"
              onClick={_handleNewTransaction}
            >
              {t("create-new-transaction")}
            </Button>
          </div>
        ) : (
          <div className="mb-4 fullWidth">
            <Button
              variant="primary"
              className="fullWidth mb-3"
              onClick={_handleFinish}
            >
              {t("finish")}
            </Button>
            <Button
              variant="outline-primary"
              className="fullWidth"
              onClick={_handleRetry}
            >
              {t("retry")}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ChargeResult;
