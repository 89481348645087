import { useCallback, useEffect, useState } from "react";
import Toolbar from "src/components/Toolbar";
import { useTranslation } from "react-i18next";
import styles from "./AddLinkCardForm.module.scss";
import Input from "src/components/Input";
import shieldWarningImg from "src/assets/images/shield-warning.svg";
import infoBlueImg from "src/assets/images/info-circle-blue.svg";
import Button from "react-bootstrap/Button";
import BottomSheet from "src/components/BottomSheet";
import cardNumberGuideImg from "src/assets/images/card-number-guide.svg";
import cardIssueDateGuideImg from "src/assets/images/card-issue-date-guide.png";
import cardExpireDateGuideImg from "src/assets/images/card-expire-date-guide.png";
import BankAccountDropdown from "src/components/BankAccountDropdown";
import Account from "src/models/Account";
import { getAccounts, requestLinkCard } from "src/api";
import UiService from "src/services/UiService";
import AccountService from "src/services/AccountService";
import MonthYearInput from "src/components/MonthYearInput";
import {
  CARD_NUMBER_DISPLAY_MAX_LENGTH,
  CARD_NUMBER_MIN_LENGTH,
  CARD_NUMBER_START,
} from "src/Constants";
import Utils from "src/utils/Utils";
import Image from "src/components/Image";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import qs from "qs";

const AddLinkCardForm = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
  const [accountList, setAccountList] = useState<Account[]>();
  const [cardNumber, setCardNumber] = useState(
    AccountService.getLinkInfo()?.sourceCardNo || ""
  );
  const [errCardNumber, setErrCardNumber] = useState("");
  const [issueDate, setIssueDate] = useState<string>(
    AccountService.getLinkInfo()?.sourceCardOpenDate || ""
  );
  const [errIssueDate, setErrIssueDate] = useState("");
  const [receiveAccount, setReceiveAccount] = useState(
    AccountService.getLinkInfo()?.accountNo || queryParams?.accountNo || ""
  );
  const [showReceiveAccountGuide, setShowReceiveAccountGuide] =
    useState<boolean>(false);
  const [showLinkConditionBottomSheet, setShowLinkConditionBottomSheet] =
    useState<boolean>(false);
  const [showCardNumberBottomSheet, setShowCardNumberBottomSheet] =
    useState<boolean>(false);
  const [showIssueDateBottomSheet, setShowIssueDateBottomSheet] =
    useState<boolean>(false);

  const _loadBankAccount = useCallback(async () => {
    try {
      const accountListRes = await getAccounts(null);
      console.log("accountListRes", accountListRes);
      setAccountList(accountListRes?.data || []);
      if (accountListRes?.data?.length > 0) {
        const defaultAccountNo =
          receiveAccount ||
          accountListRes?.data.find((item) => item.defaultAccount)?.accountNo ||
          accountListRes?.data?.[0].accountNo;
        setReceiveAccount(defaultAccountNo);
      }
    } catch (err) {
      console.log("Load link account err", err);
    }
  }, [receiveAccount]);

  useEffect(() => {
    _loadBankAccount();
  }, [_loadBankAccount]);

  const _handleChangeCardNumber = (e) => {
    const caretStart = e.target.selectionStart;
    // const caretEnd = e.target.selectionEnd;
    const previousCardNumber = cardNumber;
    const newValue = Utils.formatCardNumber(e.target.value);
    const newValueWithoutFormat = newValue.replace(/\s/g, "");
    setCardNumber(newValue);
    setErrCardNumber("");
    window.requestAnimationFrame(() => {
      let newCaretStart = caretStart;
      if (
        newCaretStart === previousCardNumber.length + 1 &&
        newValue.length > previousCardNumber.length &&
        newValueWithoutFormat.length % 4 === 1
      ) {
        newCaretStart++;
      } else if (
        newCaretStart < previousCardNumber.length + 1 &&
        newValue.length > previousCardNumber.length
      ) {
        const previousCardNumberWithoutFormat = previousCardNumber.replace(
          /\s/g,
          ""
        );
        const numOfSpaceBeforeCaret =
          previousCardNumber.length > newCaretStart
            ? Math.floor(
                previousCardNumberWithoutFormat.substr(0, newCaretStart)
                  .length / 4
              )
            : Math.floor(previousCardNumberWithoutFormat.length / 4);
        console.log("numOfSpaceBeforeCaret", numOfSpaceBeforeCaret);
        if ((newCaretStart - numOfSpaceBeforeCaret) % 4 === 0) {
          newCaretStart += 1;
        }
      }
      e.target.setSelectionRange(newCaretStart, newCaretStart);
    });
  };

  const _handleClearCardNumber = () => {
    setCardNumber("");
    setErrCardNumber("");
  };

  const _handleChangeIssueDate = (value: string) => {
    setIssueDate(value);
    setErrIssueDate("");
  };

  const _handleChangeReceiveAccount = (value) => {
    setReceiveAccount(value);
  };

  const _isValid = (): boolean => {
    const actualCardNumber = cardNumber.replace(/\D/g, "");
    if (!actualCardNumber || !actualCardNumber.trim()) {
      setErrCardNumber(t("err-card-number-required"));
      return false;
    } else if (actualCardNumber.length < CARD_NUMBER_MIN_LENGTH) {
      setErrCardNumber(t("err-min-length-card-number"));
      return false;
    } else if (!actualCardNumber.startsWith(CARD_NUMBER_START)) {
      setErrCardNumber(t("err-card-number-start"));
      return false;
    } else if (!issueDate) {
      setErrIssueDate(
        AccountService.getSelectedBank()?.requireExpDate
          ? t("err-expire-date-required")
          : t("err-open-date-required")
      );
      return false;
    } else if (
      AccountService.getSelectedBank()?.requireExpDate &&
      Utils.isMonthYearInThePast(issueDate!)
    ) {
      setErrIssueDate(t("err-invalid-expire-date"));
      return false;
    } else if (
      !AccountService.getSelectedBank()?.requireExpDate &&
      Utils.isMonthYearInFuture(issueDate!)
    ) {
      setErrIssueDate(t("err-invalid-open-date"));
      return false;
    }
    return true;
  };

  const _handleStartVerification = async () => {
    console.log("_handleStartVerification");
    const isValid = _isValid();
    if (!isValid) return;
    try {
      UiService.showLoading();
      const requestLinkCardRes = await requestLinkCard({
        accountNo: receiveAccount,
        sourceBankId: AccountService.getSelectedBank()?.id,
        sourceCardNo: cardNumber.replace(/\D/g, ""),
        sourceCardOpenDate: issueDate,
        resultUrl: `${window?.config?.webhost || ""}/link-card-result`,
      });
      console.log("requestLinkCardRes", requestLinkCardRes);
      UiService.hideLoading();
      AccountService.saveLinkInfo({
        accountNo: receiveAccount,
        sourceCardNo: cardNumber.replace(/\D/g, ""),
        sourceCardOpenDate: issueDate || "",
      });
      window.location.href = requestLinkCardRes?.data?.confirmUrl;
    } catch (err: any) {
      console.log("requestLinkCard err", err);
      UiService.hideLoading();
      if (err?.response?.status === 400) {
        console.log("Err response", err?.response);
        UiService.openPopup({
          content: t(
            `err-server-${err?.response?.data?.error_code}`,
            t("general_error")
          ),
          onlyOkButton: true,
          okTitle: t("close"),
          onOk: () => {
            UiService.closePopup();
          },
        });
      }
    }
  };

  const _handleCloseReceiveAccountBottomSheet = () => {
    console.log("_handleCloseReceiveAccountBottomSheet");
    setShowReceiveAccountGuide(false);
  };

  const _handleShowReceiveAccountBottomSheet = () => {
    setShowReceiveAccountGuide(true);
  };

  const _renderGuideReceiveAccountBottomSheet = () => {
    return (
      <BottomSheet
        title={t("guide")}
        visible={showReceiveAccountGuide}
        onClose={_handleCloseReceiveAccountBottomSheet}
      >
        <div className={styles.bottomSheetContent}>
          <div className="body14 w700 mb12">{t("receive-account")}</div>
          <div className="body14 mb32">{t("receive-account-guide")}</div>
          <Button
            variant={"primary"}
            className="fullWidth"
            onClick={_handleCloseReceiveAccountBottomSheet}
          >
            {t("i-understand")}
          </Button>
        </div>
      </BottomSheet>
    );
  };

  const _handleCloseLinkConditionBottomSheet = () => {
    setShowLinkConditionBottomSheet(false);
  };

  const _handleShowLinkConditionBottomSheet = () => {
    setShowLinkConditionBottomSheet(true);
  };

  const _renderLinkConditionBottomSheet = () => {
    return (
      <BottomSheet
        title={t("guide")}
        visible={showLinkConditionBottomSheet}
        onClose={_handleCloseLinkConditionBottomSheet}
      >
        <div className={styles.bottomSheetContent}>
          <div className="body14 w700 mb12">{t("link-condition")}</div>
          <div className="body14 mb32 prewrap">
            <div className="rowAllStart">
              <div className={styles.dot}>•</div>
              <div>{t("link-condition-guide1")}</div>
            </div>
            <div className="rowAllStart">
              <div className={styles.dot}>•</div>
              <div>{t("link-condition-guide2")}</div>
            </div>
            <div className="rowAllStart">
              <div className={styles.dot}>•</div>
              <div>{t("link-condition-guide3")}</div>
            </div>
            <div className="rowAllStart">
              <div className={styles.dot}>•</div>
              <div>{t("link-condition-guide4")}</div>
            </div>
            <br />
            <div className="rowAllStart">
              <div>{t("link-condition-guide5")}</div>
            </div>
          </div>

          <Button
            variant={"primary"}
            className="fullWidth"
            onClick={_handleCloseLinkConditionBottomSheet}
          >
            {t("i-understand")}
          </Button>
        </div>
      </BottomSheet>
    );
  };

  const _handleCloseCardNumberBottomSheet = () => {
    setShowCardNumberBottomSheet(false);
  };

  const _handleShowCardNumberBottomSheet = () => {
    setShowCardNumberBottomSheet(true);
  };

  const _renderCardNumberBottomSheet = () => {
    return (
      <BottomSheet
        title={t("guide")}
        visible={showCardNumberBottomSheet}
        onClose={_handleCloseCardNumberBottomSheet}
      >
        <div className={styles.bottomSheetContent}>
          <div className="body14 w700 mb12">{t("card-number")}</div>
          <div className="body14 mb-4 prewrap">{t("card-number-guide")}</div>
          <Image
            src={cardNumberGuideImg}
            className={styles.cardGuide}
            alt="Card Number Guide"
          />
          <Button
            variant={"primary"}
            className="fullWidth"
            onClick={_handleCloseCardNumberBottomSheet}
          >
            {t("i-understand")}
          </Button>
        </div>
      </BottomSheet>
    );
  };

  const _handleCloseIssueDateBottomSheet = () => {
    setShowIssueDateBottomSheet(false);
  };

  const _handleShowIssueDateBottomSheet = () => {
    setShowIssueDateBottomSheet(true);
  };

  const _renderIssueDateBottomSheet = () => {
    return (
      <BottomSheet
        title={t("guide")}
        visible={showIssueDateBottomSheet}
        onClose={_handleCloseIssueDateBottomSheet}
      >
        <div className={styles.bottomSheetContent}>
          <div className="body14 w700 mb12">
            {AccountService.getSelectedBank()?.requireExpDate
              ? t("expire-date")
              : t("issue-date")}
          </div>
          <div className="body14 mb-4 prewrap">
            {AccountService.getSelectedBank()?.requireExpDate
              ? t("expire-date-guide")
              : t("issue-date-guide")}
          </div>
          <Image
            src={
              AccountService.getSelectedBank()?.requireExpDate
                ? cardExpireDateGuideImg
                : cardIssueDateGuideImg
            }
            className={styles.cardGuide}
            alt="Card Issue Date Guide"
          />
          <Button
            variant={"primary"}
            className="fullWidth"
            onClick={_handleCloseIssueDateBottomSheet}
          >
            {t("i-understand")}
          </Button>
        </div>
      </BottomSheet>
    );
  };

  return (
    <div className="screenContainer">
      <Toolbar title={t("add-new-link-card")} />
      {_renderGuideReceiveAccountBottomSheet()}
      {_renderLinkConditionBottomSheet()}
      {_renderCardNumberBottomSheet()}
      {_renderIssueDateBottomSheet()}
      <div className={"screenScrollContainer"}>
        <div className={styles.container}>
          <div className="title20 mb-3">{t("input-link-info")}</div>
          <div className="body14 textGray mb-3">
            {t("input-link-info-guide")}
          </div>
          <div className="body14 textGray mb-3">
            {t("condition-before-link")}:{" "}
            <span
              onClick={_handleShowLinkConditionBottomSheet}
              className={styles.moreInfoText}
            >
              {t("more-info")}
            </span>
          </div>
          <div className="mb12">
            <Input
              label={t("link-card-number")}
              placeholder={t("link-card-number-placeholder")}
              value={Utils.formatCardNumber(cardNumber)}
              onChange={_handleChangeCardNumber}
              labelHelperIcon={
                <Image
                  src={infoBlueImg}
                  className={styles.infoBlueImg}
                  alt="Info"
                  onClick={_handleShowCardNumberBottomSheet}
                />
              }
              inputMode={"numeric"}
              errorText={errCardNumber}
              maxLength={CARD_NUMBER_DISPLAY_MAX_LENGTH}
              onClear={_handleClearCardNumber}
            />
          </div>
          <div className="mb12">
            <MonthYearInput
              placeholder={t("issue-date-placeholder")}
              label={
                AccountService.getSelectedBank()?.requireExpDate
                  ? t("expire-date")
                  : t("issue-date")
              }
              labelHelperIcon={
                <Image
                  src={infoBlueImg}
                  className={styles.infoBlueImg}
                  alt="Info"
                  onClick={_handleShowIssueDateBottomSheet}
                />
              }
              value={issueDate}
              onChange={_handleChangeIssueDate}
              errorText={errIssueDate}
            />
          </div>
          <div className="mb-4">
            <BankAccountDropdown
              label={t("receive-account")}
              labelHelperIcon={
                <Image
                  src={infoBlueImg}
                  className={styles.infoBlueImg}
                  alt="Info"
                  onClick={_handleShowReceiveAccountBottomSheet}
                />
              }
              data={accountList}
              value={receiveAccount}
              onChange={_handleChangeReceiveAccount}
            />
          </div>
          <div className={styles.warningContainer}>
            <Image
              src={shieldWarningImg}
              className={styles.shieldImg}
              alt="Shield"
            />
            <div className="body14 textBlack">{t("all-info-encrypt")}</div>
          </div>
          <div className="bottomButtonSpacer" />
        </div>
      </div>
      <div className={"bottomButtonBlock"}>
        <Button
          variant="primary"
          onClick={_handleStartVerification}
          className="fullWidth"
        >
          {t("start-verification")}
        </Button>
      </div>
    </div>
  );
};

export default AddLinkCardForm;
