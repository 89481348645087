import Toolbar from "src/components/Toolbar";
import { useTranslation } from "react-i18next";
import styles from "./LoginFailed.module.scss";
import Button from "react-bootstrap/Button";
import Utils from "src/utils/Utils";
import errorLoginImg from "src/assets/images/error-login.svg";

const LoginFailed = () => {
  const { t } = useTranslation();

  const _handleBack = () => {
    Utils.closeWebview();
  };

  return (
    <div className="screenContainer">
      <Toolbar title={t("cashin")} onBack={_handleBack} />
      <div className={`${styles.emptyStateContainer} screenScrollContainer`}>
        <img src={errorLoginImg} className={styles.img} alt="Login error" />
        <div className="title18 text-center mb-4">
          {t("general-error-title")}
        </div>
        <div className="body14 textGray text-center mb48">
          {t("login-failed-message")}
        </div>
        <Button variant="primary" onClick={_handleBack}>
          {t("close")}
        </Button>
      </div>
    </div>
  );
};

export default LoginFailed;
